.container {
  width: 100%;
  background-color: var(--header-bg);
  color: var(--white);
  position: relative;
}

.containerInner {
  margin: auto;
  max-width: var(--max-content-width);
  display: flex;
  align-items: center;
  height: var(--headerHeight);
  position: relative;
}

.logo {
  position: absolute;
  width: 18.25rem;
  min-height: 1.32rem;
  padding-left: 2.625rem;
}

@media screen and (max-width: 67rem) {
  .logo {
    position: relative;
  }

  .buttons {
    position: relative;
  }
}

.menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  padding: 1rem;
  color: #999;
  text-decoration: none;
  font-size: 1rem;
}

.active {
  color: var(--white);
}

.buttons {
  display: flex;
  position: absolute;
  right: 2.625rem;
}

@media screen and (max-width: 58rem) {
  .buttons {
    flex-direction: column;
    right: 0;
  }

  .buttons .link {
    padding: 0;
  }
}

.icon {
  font-size: 1.5rem;
}

.icon,
.icon .link {
  color: var(--gray);
}

.active .icon {
  color: var(--red);
}
