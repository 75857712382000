/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import 'fonts.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* End of reset */

/* Global variables */
:root {
  --header-bg: #1d1d1b;
  --subheader-bg: #444442;
  --content-bg: #fff;
  --white: #fff;
  --black: #000;
  --checkmark-green: #4eb04a;
  --red: #f00;
  --dark-red: #e30613;
  --green: #3aaa35;
  --yellow: #f4a021;
  --icon-gray: #555;
  --light-gray: #d6d6d6;
  --gray: #aaaaa9;
  --dark-gray: #6b6b6b;
  --side-panel-color: #ebebeb;
  --gray-separator: #ebebeb;
  --pink: #fbd7da;
  --headerHeight: 72px;
  --subheaderHeight: 54px;
  --max-content-width: 1440px;
}

/* stylelint-disable-next-line no-duplicate-selectors */
body {
  margin: 0;
  font-family: MaisonNeue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--content-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  min-height: 50rem;
  background-color: var(--content-bg);
}

.content {
  width: 100%;
  max-width: 87.5rem;
  margin: auto;
  margin-top: 1.25rem;
}
