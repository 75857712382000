.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.loading span {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  animation-name: dots;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.big span {
  width: 1.5rem;
  height: 1.5rem;
}

.loading span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes dots {
  50% {
    opacity: 0;
    transform: scale(0.7) translateY(10px);
  }
}

.red {
  background-color: #f44336;
}

.orange {
  background-color: #ff9800;
}

.green {
  background-color: #4caf50;
}

.text {
  margin-bottom: 10px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
