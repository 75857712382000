.container {
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  font-size: 1.2rem;
}

.formContainer {
  background-color: var(--white);
  padding: 2rem;
}
