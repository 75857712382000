.container {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
}
