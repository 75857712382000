.container {
  display: flex;
  flex-wrap: wrap;
}

.field {
  background-color: var(--white);
  color: var(--black);
}

.field .MuiInput-underline {
  content: '';
  display: none;
}

.clearBtn {
  cursor: pointer;
}

.hideBtn {
  visibility: hidden;
}

.icon {
  color: #8a8a8a;
  font-size: 1.5rem;
}
