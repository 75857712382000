.container {
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.header {
  font-family: MaisonNeueExtendedBook, Arial, sans-serif;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.4rem;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
}

.headerButton + .headerButton {
  margin-left: 0.5rem;
}

.error {
  color: red;
}
