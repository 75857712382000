.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding-bottom: 1rem;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
