.container {
  background-color: #f5f5f5;
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.header {
  font-family: MaisonNeueExtendedBook, Arial, sans-serif;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  overflow-x: hidden;
  font-size: 1.4rem;
  padding-right: 2.625rem;
}

.paper {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  min-height: 18.75rem;
}

.emptyTitle {
  font-family: MaisonNeueBook, Arial, sans-serif;
  font-size: 1.4rem;
}

.iconCircle {
  height: 7rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 5rem;
  margin: 1rem 0;
}

.emptyIcon {
  font-size: 3rem;
  color: var(--dark-gray);
}
