.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.illustration {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  font-family: MaisonNeueExtended, Arial, sans-serif;
  display: flex;
  justify-content: flex-start;
  background-image: -webkit-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: -moz-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: -o-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: radial-gradient(circle at top left, #f30e2b, #000);
}

.content {
  background-color: #efefef;
  width: 35.6rem;
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 2.7rem;
  position: relative;
  z-index: 2;
}

.content h1 {
  padding-top: 7.25rem;
  font-family: MaisonNeueExtended, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.logo {
  width: 18.25rem;
  min-height: 6.875rem;
  padding-top: 1rem;
}

.form {
  color: var(--dark-gray);
  width: 30.5rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.6rem;
}

.formElement {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.formElement + .formElement {
  padding-top: 1.25rem;
  padding-bottom: 2rem;
}

.formField {
  background: var(--white);
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2.8rem;
}

.links *:link,
.links *:visited {
  color: var(--black);
  line-height: 1.5rem;
}

.bigEye {
  position: absolute;
  left: 142px;
  bottom: -114px;
  pointer-events: none;
  z-index: -1;
}

.slogan {
  color: white;
  padding-top: 7.5rem;
  padding-left: 2.75rem;
}

.slogan > h1 {
  width: 22.4rem;
  font-size: 4rem;
  line-height: 6rem;
  letter-spacing: 0;
}

.slogan > h3 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 2.625rem;
  line-height: 4rem;
}
