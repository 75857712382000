.container {
  width: 100%;
  min-height: calc(100vh - 50rem);
  margin-bottom: 2rem;
}

.noEntryText {
  color: var(--dark-gray);
  padding-right: 1.3rem;
}

.datetime {
  display: flex;
  align-items: center;
}

.icon {
  color: #9f9f9f;
  font-size: 18px;
  padding-right: 1rem;
}

.link {
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}
