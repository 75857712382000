@font-face {
  font-family: MaisonNeue;
  src: url('./fonts/SafeSolutions/MaisonNeue-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueDemi;
  src: url('./fonts/SafeSolutions/MaisonNeue-Demi.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueBook;
  src: url('./fonts/SafeSolutions/MaisonNeue-Book.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueExtended;
  src: url('./fonts/SafeSolutions/MaisonNeueExtended-Medium.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueExtendedDemi;
  src: url('./fonts/SafeSolutions/MaisonNeueExtended-Demi.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueExtendedBook;
  src: url('./fonts/SafeSolutions/MaisonNeueExtended-Book.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: MaisonNeueDemi;
  src: url('./fonts/SafeSolutions/MaisonNeue-Demi.otf') format('opentype');
  font-display: swap;
}
