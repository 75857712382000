.container {
  width: 100%;
  background-color: var(--subheader-bg);
  box-shadow: 0 0.125rem 0.5rem 0.125rem rgba(0, 0, 0, 0.16);
  z-index: 999;
}

.containerInner {
  max-width: var(--max-content-width);
  margin: auto;
  display: flex;
  height: var(--subheaderHeight);
  position: relative;
  align-items: center;
}

.siteSelector {
  margin-left: 2.625rem;
}

.menu {
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-right: 2.265rem;
}

.link {
  height: 3.375rem;
  color: var(--gray);
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: initial;
  opacity: 1;
}

.active {
  color: var(--white);
}
