.container {
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.header {
  font-family: MaisonNeueExtendedBook, Arial, sans-serif;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  overflow-x: hidden;
  font-size: 1.4rem;
}

.link {
  text-decoration: none;
}

.innerContent {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  width: 20rem;
}

.downloadButton {
  margin-top: 2rem;
}

.formControl {
  margin-top: 1rem;
}
