.button {
  color: var(--white);
}

.button > .MuiButton-label {
  font-family: MaisonNeueDemi, Arial, sans-serif;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 21px;
  text-decoration: none;
  padding: 7.5px 20px;
  text-align: center;
}

.primary {
  background-color: var(--red);
}

.primary:hover {
  background-color: var(--red);
  filter: brightness(75%);
}

.disabled {
  background-color: var(--gray);
}

.outlined {
  color: var(--black);
}

.outlined:hover {
  color: var(--black);
  filter: brightness(75%);
}

.secondary {
  background-color: var(--green);
}

.secondary:hover {
  background-color: var(--green);
  filter: brightness(75%);
}

.link {
  text-decoration: none;
}

.outlined .link {
  color: var(--black);
}
