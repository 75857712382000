.container {
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  width: 100%;
  display: flex;
  margin-top: 1.25rem;
}

.listSection {
  width: 40%;
  flex-grow: 2;
  padding: 0 0.5rem;
}

.search {
  width: 100%;
}

.userList {
  display: flex;
  align-items: center;
}

.selectSublist {
  padding-left: 1rem;
}

.right {
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-grow: 4;
  margin-left: 0.5rem;
}

.right > * {
  width: 100%;
}

.rightTitle {
  height: 4rem;
  display: flex;
  align-items: center;
  font-family: MaisonNeueExtendedBook, Arial, sans-serif;
  font-size: 1.375rem;
  line-height: 2rem;
  padding-bottom: 0.25rem;
}

.subjectFormControl {
  width: 100%;
  padding-top: 1.75rem;
}

.subjectHelperText {
  display: flex;
  justify-content: space-between;
}

.markdownLink {
  color: #0007;
  font-size: 0.8rem;
}

.message {
  width: 100%;
  padding-top: 2rem;
}

.messageHelperText {
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
}

.footer > * {
  margin-left: 1rem;
}

.sendBtn:disabled {
  background-color: var(--green);
  opacity: 54%;
}

.sendBtn:disabled > span {
  color: var(--white);
}
