.container {
  width: 20rem;
  background-color: gray;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
}

.title {
  padding-bottom: 0.5rem;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0.2rem;
  margin: 0.2rem 0;
  cursor: pointer;
}

.card:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.card:active {
  background-color: rgba(255, 255, 255, 0.5);
}

.cardSubject,
.cardTimestamp {
  flex-grow: 2;
  flex-wrap: wrap;
}

.cardSubject + .cardTimestamp {
  margin-left: 0.5rem;
}

.modal {
  min-width: 30rem;
  max-width: 50%;
  color: var(--black);
  margin: auto;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.modalSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: var(--white);
  color: var(--black);
}

.modalSection h3 {
  font-size: 1.5rem;
}

.modalSection.header {
  padding-bottom: 1rem;
}

.modalSection > p {
  font-size: 1rem;
}

.unreadBtn {
  color: var(--black);
  font-size: 0.8rem;
  border-color: var(--black);
}
