.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  width: 100%;
  max-width: var(--max-content-width);
  display: flex;
}

.content {
  background-color: var(--white);
  width: 100%;
  display: flex;
  padding-left: 2rem;
  padding-top: 2rem;
}

.sidebar {
  height: calc(100vh - var(--headerHeight));
}

.title {
  font-family: MaisonNeueExtended, Arial, sans-serif;
  font-size: 1.25rem;
  padding-bottom: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 2.625rem;
}
