.mouseOver {
  opacity: 0;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
}

.row:hover .mouseOver {
  opacity: 1;
}

.mouseOverText {
  font-size: 1rem;
}
