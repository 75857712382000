.form {
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
}

.entry {
  display: flex;
  flex-grow: 2;
}

.row .entry:first-child {
  padding-right: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}
