.form {
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  padding: 0.4rem 0;
}

.entry {
  display: flex;
  flex-grow: 1;
}

.row .entry:first-child {
  padding-right: 0.5rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}
