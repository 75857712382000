.container {
  width: 14rem;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.container.header {
  background-color: #515151;
  color: var(--white);
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.searchbar {
  padding: 0.7rem 1rem;
}

.searchbar .MuiInput-underline {
  content: '';
  display: none;
}

.currentSite {
  display: flex;
  padding: 0.7rem 0;
  padding-left: 1rem;
  align-items: center;
}

.entry {
  display: flex;
  align-items: center;
  padding: 0;
}

.redEye {
  color: var(--dark-red);
  padding-right: 1rem;
}

.grayEye {
  color: var(--light-gray);
  padding-right: 1rem;
}

.icon {
  padding-right: 1rem;
}

.dropdownIcon {
  color: #cbcbca;
}

.row {
  padding: 0.3rem 0;
  color: var(--dark-gray);
}

.container::before,
.container::after {
  /* removes bottom border */
  content: '';
  display: none;
}

.emptyList {
  display: flex;
  flex-wrap: wrap;
  font-style: italic;
  color: var(--dark-gray);
  padding: 0.5rem 0.3rem;
}
