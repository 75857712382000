.container {
  width: 21rem;
  min-height: calc(100vh - var(--headerHeight) - var(--subheaderHeight));
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--side-panel-color);
}

.container::before {
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  content: '';
  z-index: -2;
}

@media screen and (max-width: 62.5rem) {
  .container {
    display: none;
  }
}

.backButton {
  padding-top: 2rem;
  padding-left: 2.5rem;
}

.icon {
  color: var(--icon-gray);
  font-size: 2rem;
  padding-top: 1.7rem;
  padding-left: 2.625rem;
}

.title {
  font-family: MaisonNeueExtendedDemi, Arial, sans-serif;
  font-size: 1.625rem;
  padding: 1.375rem 3.5rem 0 2.625rem;
  line-height: 2.44rem;
}

.text {
  padding: 0.625rem 1.5rem 0 2.5rem;
  font-family: MaisonNeueExtendedBook, Arial, sans-serif;
  line-height: 1.5rem;
  color: #212121;
}

.link {
  color: var(--icon-gray);
  text-decoration: none;
}
