.container {
  display: flex;
  flex-direction: column;
}

.info {
  line-height: 1.5rem;
}

.notSetup {
  display: flex;
  align-items: center;
  background-color: rgba(243, 146, 0, 0.24);
  border: 1px solid rgba(243, 146, 0, 0.87);
  border-radius: 0.25rem;
  margin: 1.5rem 0;
}

.notSetupText {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningIcon {
  background-color: rgba(243, 146, 0, 0.87);
  color: var(--white);
  padding: 0.8rem;
}

.button {
  display: flex;
  justify-content: flex-end;
}
