.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.group {
  flex-basis: 40%;
}

.groupHeader {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.groupPermissions {
  font-size: 0.8rem;
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  white-space: nowrap;
}
