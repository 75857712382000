.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.formContainer {
  width: 100%;
  background-color: var(--white);
  justify-content: flex-start;
  margin-top: 1rem;
}

.form {
  width: 100%;
  max-width: 31.25rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem;
}

.selects {
  display: flex;
  padding-top: 0.5rem;
}

.select {
  display: flex;
  justify-content: center;
  flex-grow: 2;
}

.selects > .select + * {
  margin-left: 0.8rem;
}

.error {
  font-size: 2rem;
  padding: 2rem;
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
}

.siteRetainDaysSelect {
  min-width: 180px;
  align-self: flex-start;
}

.infoButton {
  margin-left: auto;
}
