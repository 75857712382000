.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.illustration {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  font-family: MaisonNeueExtended, Arial, sans-serif;
  display: flex;
  justify-content: flex-start;
  background-image: -webkit-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: -moz-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: -o-radial-gradient(circle at top left, #f30e2b, #000);
  background-image: radial-gradient(circle at top left, #f30e2b, #000);
}

.content {
  background-color: #efefef;
  width: 50rem;
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 2.7rem;
}

.content h1 {
  padding-top: 116px;
  font-size: 26px;
  line-height: 39px;
  padding-bottom: 1rem;
}

.content p + p {
  padding-top: 1rem;
}

.logo {
  width: 18.25rem;
  min-height: 6.875rem;
  padding-top: 1rem;
  cursor: pointer;
}

.bigEye {
  position: absolute;
  left: 142px;
  bottom: -114px;
  pointer-events: none;
  z-index: 1;
}

.slogan {
  color: white;
  padding-top: 7.5rem;
  padding-left: 2.75rem;
}

@media screen and (max-width: 37rem) {
  .illustration,
  .bigEye {
    display: none;
  }

  .content {
    width: 100vw;
    padding: 0.5rem;
  }

  .logo {
    width: 90vw;
    align-self: center;
  }
}
