.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  color: var(--black);
}

.search {
  margin-right: 2.625rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--light-gray);
}

.list {
  width: 100%;
}

.row {
  text-align: left;
}

.row > * {
  padding-left: 1.5rem;
}

.row button {
  border-radius: 0;
}

.td {
  max-width: 27rem;
  vertical-align: middle;
}

.header {
  background: #f5f5f5;
  color: #888;
}

.activeHeader {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.row + .row {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
