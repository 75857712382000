.container {
  width: 100%;
  overflow-x: hidden;
}

.containerInner {
  background-color: #efefef;
  max-width: var(--max-content-width);
  display: flex;
  margin: auto;
}

.form {
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem;
}

.headerTitle {
  display: flex;
  align-items: center;
}

.name {
  font-family: MaisonNeueExtended, Arial, sans-serif;
  font-size: 1.5rem;
}

.name > span {
  opacity: 0.5;
  margin-left: 1rem;
}

.timeoutButton {
  font-size: 2rem;
  color: black;
  margin-right: 1.5rem;
}

.active {
  background-color: #f39200;
}

.inactive {
  background-color: var(--red);
}

.headerButtons > * + * {
  margin-left: 1rem;
}

.body {
  background-color: var(--white);
  width: 100%;
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
}

.body > * + * {
  margin-left: 1rem;
}

.timeoutBlock {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.timeoutBlock > * {
  display: flex;
  padding: 0.7rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
}

.status {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
}

.statusText.active {
  background-color: #ff01;
}

.statusText.inactive {
  background-color: #f001;
}

.forceDeactButton {
  align-self: flex-start;
  font-weight: bold;
  letter-spacing: 1px;
}

.forceDeactButton > * {
  color: var(--black);
}

.endsAtText {
  background-color: #f001;
}

.activatedByText {
  background-color: #0001;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
}

.activatedByText > * + * {
  padding-top: 0.5rem;
}

.activatedByIcon {
  color: var(--dark-gray);
  padding-right: 1rem;
}

.activatedById {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.siteInfo {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.confirmDel {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
}

.delButton {
  padding-left: 2rem;
  padding-right: 2rem;
}
