.title {
  font-size: 1.25rem;
}

.section {
  padding: 2.25rem 0;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
